<template>
  <div class="pageMyCoursesSelect">
    <div
      ref="button"
      class="pageMyCoursesSelect__button"
      @click="showPopup = !showPopup"
    >
      <div class="pageMyCoursesSelect__title">
        {{ selected ? selected : $t("my_courses.todos") }}
      </div>
      <div class="pageMyCoursesSelect__icon">
        <IconSax size="1rem" name="arrow-down-1" />
      </div>
    </div>
    <div
      v-show="showPopup"
      v-closable="{
        exclude: ['button'],
        handler: 'onClose',
      }"
    >
      <div class="pageMyCoursesSelect__button">
        <div class="pageMyCoursesSelect__icon">
          <div
            class="pageMyCoursesSelect__dropdown"
            v-show="showPopup"
            v-closable="{ exclude: ['button'], handler: 'onClose' }"
          >
            <div class="pageMyCoursesSelect__menu">
              <ul class="pageMyCoursesSelect__menu--listing">
                <li
                  class="pageMyCoursesSelect__menu--listing-item pageMyCoursesSelect__border"
                  @click="select('')"
                >
                  {{ $t("my_courses.todos") }}
                </li>
                <li
                  v-for="(item, i) in data"
                  :key="i"
                  class="pageMyCoursesSelect__menu--listing-item"
                  :class="{ pageMyCoursesSelect__border: i < data.length - 1 }"
                  @click="select(item)"
                >
                  {{ item[propLabel] }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      show: false,
      showPopup: false,
      selected: $t("my_courses.todos")
    };
  },
  props: {
    data: {
      type: [Object, Array],
      default: () => ({}),
    },
    propKey: {
      type: String,
      default: "",
    },
    propLabel: {
      type: String,
      default: "",
    },
  },
  methods: {
    onClose() {
      this.showPopup = false;
    },
    select(value) {
      if (value === "") {
        this.$emit("select", "");
        this.selected = $t("my_courses.todos");
      } else {
        this.$emit("select", value);
        this.selected = value[this.propLabel];
      }
      
      this.showPopup = false;
    },
    toggle() {
      this.showPopup = !this.showPopup;
    },
  },
};
</script>

<style lang="scss" scoped>
.pageMyCoursesSelect {
  height: 20px;

  &__title {
    @extend .body-regular-14;
    width: 110px;
    display: flex;
    justify-content: flex-start;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__icon {
    height: 16px;
  }
  &__title,
  &__icon {
    ::v-deep .svgCss svg {
      width: 16px;
      height: 16px;
    }
  }

  &__border {
    border-bottom: var(--select-filter-border);
  }

  &__button {
    cursor: pointer;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    color: var(--opposite-color);
    ::v-deep .svgCss svg path {
      fill: var(--opposite-color);
    }

    &:hover {
      color: var(--maincolor);
      ::v-deep .svgCss svg path {
        fill: var(--maincolor);
      }
      .isax, i {
        color: var(--maincolor) !important;
      }
    }
  }
  &__dropdown {
    position: relative;
    z-index: 10;
    margin: 0px 0px;
  }
  &__menu {
    border-radius: 8px;
    border: var(--select-filter-border);
    background: var(--select-filter-background-color);
    display: flex;
    width: 200px;
    max-width: 200px;
    min-width: 170px;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
    margin-top: 8px;

    @media screen and (max-width: 414px) {
      width: calc(100% - 35px);
      max-width: 100%;
      min-width: 220px;
    }

    &--listing {
      list-style: none;
      padding-left: 0px;
      margin-bottom: 0px;
      width: 100%;
      &-item {
        display: flex;
        padding: 10px 0px;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        width: 100%;
        margin-bottom: 10px;
     
        @extend .body-semibold-14;
        color: var(--opposite-color);
        cursor: pointer;
        &:hover {
          color: var(--maincolor);
        }

        &:active {
        }
      }
    }
  }
}
</style>
