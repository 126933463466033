<template>
  <Layout>
    <div class=" my-courses my-courses-skeleton" v-if="getIsLoading">
      <div class="my-courses-skeleton-header ">
        <div class="my-courses-skeleton-header__title skeleton"> </div>
        <div class="my-courses-skeleton-header__filters skeleton"></div>
      </div>
      <div class="my-courses-skeleton-panel skeleton"></div>
      <div class="my-courses-skeleton-body skeleton" v-for="n in 8" :key="n"></div>
    </div>

    <div class="my-courses" v-else>
      <!-- HEADER -->

      <div class="my-courses-header">

        <TitlePage :title="$t('modal_my_courses.title')">
          <IconSax name="video-play" />
        </TitlePage>

        <div class="my-courses-header__filters">
          <div class="my-courses-header__filters--panel">
            <span class="my-courses-header__filters--panel__label">{{ $t("my_courses.painel") }}</span>
            <Select :data="uniqueSites" propKey="siteId" propLabel="siteName" @select="setSite" />
          </div>
          <div class="my-courses-header__filters--panel">
            <span class="my-courses-header__filters--panel__label">{{ $t("my_courses.status") }}</span>
            <Select :data="status" propKey="value" propLabel="name" @select="setStatus" />
          </div>
        </div>
      </div>

      <div class="my-courses-listing" v-for="(panel, key) in filteredData" :key="key">
        <div v-if="panel.data.length > 0">
          <div class="my-courses-listing__title">
            <div class="my-courses-listing__title--panel">
              {{ panel.siteName }}
            </div>

            <div class="my-courses-listing__title--openPanel" @click="redirectTo(panel)">
              {{ $t("my_courses.ver") }}
              <IconSax size="1rem" primary type="outline" name="export-3" />
            </div>
          </div>
          <div class="my-courses-listing__courses">
            <ul class="my-courses-listing__courses--list">
              <li v-for="(course, index) in panel.data" :key="index">
                <ListMyCourse :data="course" @open="gotoCourse(panel, course)" :completed="course.percentage_complete === 100 ||
                  (course.percentage_release_certificate !== null &&
                    course.percentage_complete >=
                    course.percentage_release_certificate)
                  " />
              </li>
              <li v-if="panel.data.length === 0" class="my-courses-listing__courses--list--empty">
                <div class="">{{ $t("my_courses.cursos") }}</div>
              </li>
            </ul>
          </div>
        </div>

        <hr class="my-courses-listing__hr" v-if="panel.data.length > 0 && key < filteredData.length - 1" />
      </div>
      <infinite-loading @infinite="infiniteHandler" v-if="!getIsLoading">
        <div slot="no-more">
          <p class="body-regular-14 color-base">{{ $t("my_courses.more_courses") }}</p>
        </div>
        <div slot="no-results">
          <p class="body-regular-14 color-base">{{ $t("my_courses.cursos") }}</p>
        </div>
      </infinite-loading>
    </div>
  </Layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import ListMyCourse from "@/components/pages/my-courses/ListMyCourse.vue";
import Layout from "@/layout/Layout.vue";
import Select from "@/components/pages/my-courses/Select.vue";
import TitlePage from "@/components/common/TitlePage.vue";
import InfiniteLoading from 'vue-infinite-loading';

export default {
  data() {
    return {
      selectedPanel: "",
      statusCourse: 0,
      page: 1,
      status: [
        { name: "Em andamento", value: "progress" },
        { name: "Concluído", value: "complete" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getHomeData: "home/getHomeData",
      getMe: "auth/getMe",
      getListCourses: "myCourses/getListCourses",
      getIsLoading: "config/getIsLoading",
    }),
    coursesBySiteID() {
      return this.organizeCoursesBySiteId(
        this.getListCourses.courses,
        this.getMe?.member?.sites
      );
    },
    filteredData() {
      const courses = this.coursesBySiteID
        .map((item) => {
          if (this.statusCourse === "complete") {
            const course = item.data
              .filter((course) => {
                return (
                  course.percentage_release_certificate !== null &&
                  course.percentage_complete >=
                  course.percentage_release_certificate
                );
              })
              .filter((c) => c);

            return {
              ...item,
              ...(this.statusCourse ? { data: course } : null),
            };
          }

          if (this.statusCourse === "progress") {
            const course = item.data
              .filter((course) => {
                return course.percentage_complete > 0 && course.percentage_release_certificate === null ? course : null;
              })
              .filter((c) => c);

            return {
              ...item,
              ...(this.statusCourse ? { data: course } : null),
            };
          }

          return item;
        })
        .filter((item) => {
          const panelObj =
            !this.selectedPanel || item.siteId === parseInt(this.selectedPanel);
          return panelObj;
        });

      const id = this.getMe?.current?.currentSite?.id;

      const currentPanel = this.currentPanelFirst(courses, id)

      return currentPanel;
    },
    uniqueSites() {
      const unique = {};
      return this.coursesBySiteID.filter((item) => {
        if (!unique[item.siteId]) {
          unique[item.siteId];
          return true;
        }
        return false;
      });
    },
  },
  components: {
    Layout,
    ListMyCourse,
    Select,
    TitlePage
  },
  methods: {
    ...mapActions({
      actionFetchAllCoursesData: "myCourses/actionFetchAllCoursesData",
    }),
    infiniteHandler($state) {
      if (this.page === this.getListCourses.meta.last_page) {
        $state.complete();
        return;
      }

      this.page += 1;

      this.actionFetchAllCoursesData({ page: this.page })
        .then(() => {
          $state.loaded();
        })
        .catch((e) => {
          console.log(e)
          $state.error();
        });
    },
    async gotoCourse(site, course) {
      await this.$store.dispatch("auth/actionDeepNavigate", {
        ...site,
        course_id: course.id,
        current: this.$route.path
      });
    },
    currentPanelFirst(array, id) {
      const objectToMove = array.find(obj => obj.siteId === id);

      if (objectToMove) {
        const index = array.indexOf(objectToMove);
        array.splice(index, 1);
        array.unshift(objectToMove);
      }

      return array
    },
    setSite(site) {
      this.selectedPanel = site.siteId;
    },
    setStatus(course) {
      if (undefined === course.value) {
        this.statusCourse = null;
      }
      this.statusCourse = course.value;
    },
    organizeCoursesBySiteId(jsonData, userSites) {
      const mergedData = jsonData?.map((course) => {
        const matchingSite = userSites.find((site) => {
          return site.id === course.site.id;
        });

        return { ...course, site: { ...course.site, ...matchingSite } };
      });

      const groupedData = mergedData?.reduce((groups, course) => {
        const {
          id: siteId,
          name: siteName,
          subdomain,
          Auth,
          role,
          laravel_through_key,
          logo,
          domains,
          description
        } = course.site;

        if (!groups[siteId]) {
          groups[siteId] = {
            siteId,
            siteName,
            subdomain,
            Auth,
            role,
            laravel_through_key,
            logo,
            domains,
            description,
            data: [],
          };
        }

        groups[siteId].data.push(course);
        return groups;
      }, {});


      return Object.values(groupedData || {});
    },
    redirectTo(painel) {
      painel = {
        ...painel,
        current: this.$route.path
      }
      this.$store.dispatch("auth/actionDeepNavigate", painel);
    },
  },
};
</script>

<style lang="scss" scoped>
.my-courses {

  /* Skeleton */
  &-skeleton {
    &-header {
      margin-top: var(--spacing-36);
      margin-bottom: 56px;
      display: flex;
      height: 30px;
      gap: 30px;

      &__title {
        flex-basis: 50%;
      }

      &__filters {
        flex-basis: 50%;
      }
    }

    &-panel {
      width: 150px;
      height: 20px;
      margin-bottom: 20px;
    }

    &-body {
      height: 72px;
      margin-bottom: 15px;
    }
  }

  /* Component */
  padding: var(--spacing-24) var(--spacing-16);

  @media screen and (min-width: 768px) {
    padding: 70px var(--spacing-36);
  }

  &-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 56px;
    margin-top: var(--spacing-36);
    flex-direction: column;
    gap: 30px;

    @media screen and (min-width: 768px) {
      margin-top: 0px;
      flex-direction: row;
      align-items: center;
    }

    &__title {
      @extend .headline-semibold-24;
      color: var(--opposite-color);
      cursor: default;
      flex-basis: 100%;

      @media screen and (min-width: 768px) {
        // flex-direction: row;
        // align-items: center;
      }
    }

    &__filters {
      width: 100%;
      display: flex;
      gap: 30px;
      cursor: default;
      flex-direction: column;

      @media screen and (min-width: 768px) {
        flex-direction: row;
        justify-content: flex-end;
      }

      &--panel {
        display: flex;
        align-items: center;
        gap: 20px;
        justify-content: space-between;
        color: var(--my-courses-color);

        ::v-deep .pageMyCoursesSelect {
          width: 100%;
          min-width: 200px;
        }


        ::v-deep .pageMyCoursesSelect__button {
          justify-content: space-between;
        }

        @media screen and (min-width: 768px) {
          flex-direction: row;
          justify-content: flex-start;

          ::v-deep .pageMyCoursesSelect__button {
            justify-content: space-start;
          }
        }

        &__label {
          @extend .body-bold-14;
        }
      }
    }
  }

  &-listing {
    &__hr {
      height: 1px;
      border-color: var(--my-courses-separator);
      margin: 40px 0px;
    }

    &__title {
      @extend .body-semibold-20;
      color: var(--font-color);
      margin-bottom: 20px;
      display: flex;
      gap: 12px;

      &--panel {
        display: flex;
        align-items: center;
        gap: 20px;
        cursor: default;
        color: var(--my-courses-color);
      }

      &--openPanel {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 8px;
        color: var(--maincolor);
        @extend .body-regular-14;

        ::v-deep svg {
          width: 14px;
          height: 14px;

          >path {
            fill: var(--maincolor);
          }
        }

        &:hover {
          opacity: 0.7;
        }
      }
    }

    &__courses {
      &--list {
        list-style: none;
        flex-direction: column;
        padding-left: 0;
        display: flex;
        gap: 15px;
        margin-bottom: 0px;

        &--empty {
          text-align: center;
          height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
</style>
